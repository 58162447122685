<template>
  <div>
    <div class="question-class">
      <a-form :colon="false" id="components-form-demo-validate-other" v-for="(item, index) in listActivityQuestionInfo"
              :key="index" v-bind="formItemLayout" @submit="handleSubmit">
        <a-form-item :label="'问题' + `${index + 1}：`">
          <a-row :gutter="8">
            <a-col :span="12">
              <a-input v-model.trim="item.questionContent" @change="questionContentChange(item)" :disabled="isDisabled || item.disabled" :max-length="50"
              />
            </a-col>
            <a-col :span="12">
              <a-button type="danger" :disabled="isDisabled" @click="removeQuestion(index)">删除</a-button>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item label=" ">
          <a-checkbox v-model="item.whetherRequired"  :disabled="isDisabled || item.disabled">
            必填
          </a-checkbox>
          <a-checkbox v-model="item.whetherToShow"  :disabled="isDisabled || item.disabled" v-if="item.questionType !== 3">
            展示填报信息
          </a-checkbox>
          <a-checkbox v-model="item.questionLevelFlag" :disabled="isDisabled || !item.questionContent" @click="handleWhetherOne($event, item, index)">
            一级问题
          </a-checkbox>
          <a-checkbox v-model="item.refQuestionFlag" @click="handleWhetherTwo($event, item)" :disabled="isDisabled" v-show="item.questionType === 2 && item.questionLevelFlag">
            选项关联二级问题
          </a-checkbox>
        </a-form-item>
        <a-form-item label=" " v-if="item.questionType === 1">
          最少填：
          <a-input-number v-model="item.leastWord" :disabled="isDisabled || item.disabled"/>
          最多填：
          <a-input-number v-model="item.mostWord" :disabled="isDisabled || item.disabled"/>
        </a-form-item>
        <div v-if="item.questionType === 2 || item.questionType === 5">
          <a-form-item
              v-for="(k, optionIndex) in item.answerJson[0].values"
              :key="k"
              v-bind="formItemLayoutWithOutLabel"
              :label="' '"
              :required="false"
          >
            {{ `选项${optionIndex + 1}：` }}
            <a-input v-model="k.text" :max-length="15" :disabled="isDisabled" placeholder="请输入"
                     style="width: 30%; margin-right: 8px"
            />
            <a-icon
                v-if="item.answerJson[0].values.length > 1"
                class="dynamic-delete-button"
                type="minus-circle-o"
                :disabled="item.answerJson.length === 1 || isDisabled"
                @click="() => remove(optionIndex,index)"
            />
            <!--关联问题-->
            <div v-show="item.refQuestionFlag">
              关联题目：
              <a-select
                :disabled="isDisabled || !k.text"
                v-model="k.refQuestionId"
                placeholder="请选择关联题目"
                :style="{ width: '200px' }"
                allowClear
              >
                <a-select-option v-for="q in relevancyQuestions" :key="q.refQuestionFontId" :value="q.refQuestionFontId">
                  {{ q.questionContent }}
                </a-select-option>
              </a-select>
            </div>
          </a-form-item>
          <a-form-item v-bind="formItemLayoutWithOutLabel">
            <a-button type="dashed" style="width: 40%" :disabled="isDisabled" @click="add(index)">
              <a-icon type="plus"/>
              新增选项
            </a-button>
          </a-form-item>
        </div>
        <a-form-item label=" " v-if="item.questionType === 3">
          最多上传：
          <a-input-number :disabled="isDisabled" v-model="item.imgAmount"/>
        </a-form-item>
        <div v-if="item.questionType === 4">
          <a-form-item
              v-for="(k, optionIndex) in item.userLimitJson"
              :key="k"
              v-bind="formItemLayoutWithOutLabel"
              :label="' '"
              :required="false"
          >
            {{ `选项${optionIndex + 1}：` }}
            <a-input v-model="k.text" :max-length="15" :disabled="isDisabled" placeholder="请输入"
                     style="width: 20%; margin-right: 8px"
            />
            {{ `总额度${optionIndex + 1}：` }}
            <a-input-number :max-length="15" v-model="k.total" :min="1" :disabled="isDisabled" placeholder="请输入"
                     style="width: 20%; margin-right: 8px"
            />
            个
            <a-icon
                v-if="item.userLimitJson.length > 1"
                class="dynamic-delete-button"
                type="minus-circle-o"
                :disabled="item.userLimitJson.length === 1 || isDisabled"
                @click="() => removeUserLimitOption(optionIndex,index)"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayoutWithOutLabel">
            <a-button type="dashed" style="width: 40%" :disabled="isDisabled" @click="addUserLimitOption(index)">
              <a-icon type="plus"/>
              新增选项
            </a-button>
          </a-form-item>
        </div>
        <a-form-item label="最多可选" v-if="item.questionType === 5">
          <a-input-number :max-length="2" :min="1" @change="mostSelectHandleChange(item, index)" v-model="item.mostSelect" :disabled="isDisabled" placeholder="请输入"/>
        </a-form-item>


      </a-form>
    </div>
    <a-divider style="width: 400px;"/>
    <div class="question-type-class">
      <div class="common-question">
          <span>常用问题：</span>
          <a-select
            :disabled="isDisabled"
            v-model="selectedCommonQuestions"
            style="width: 200px"
            mode="multiple"
            @change="handleSelectCommonQuestion"
            @deselect="handleDeselectCommonQuestion"
            placeholder="请选择常用问题"
          >
            <a-select-option v-for="item in commonQuestions" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        <div style="color: red; font-weight: bolder; margin-top: 10px">注：用户报名该活动时会自动带出常见问题对应的用户信息、企业信息</div>
      </div>


      <a-button type="primary" :disabled="isDisabled" @click="addQuestion(1)">
        填空题
      </a-button>
      <a-button type="primary" :disabled="isDisabled" @click="addQuestion(2)">
        单选题
      </a-button>
      <a-button type="primary" :disabled="isDisabled" @click="addQuestion(3)">
        图片上传
      </a-button>
      <a-button type="primary" :disabled="isDisabled" @click="addQuestion(4)">
        名额限制单选项
      </a-button>
      <a-button type="primary" :disabled="isDisabled" @click="addQuestion(5)">
        多选题
      </a-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "stepThree",
  data() {
    return {
      // 可关联的问题
      relevancyQuestions: [],
      // 基础模版问题
      basicTemplate: {
        // 问题名称
        questionContent: '',
        // 最少字数限制
        leastWord: 1,
        // 最多字数限制
        mostWord: 100,
        // 问题类型
        questionType: '',
        // 是否必填项，1：必填 0：不必填
        whetherRequired: 1,
        // 是否在我的活动页详情中展示
        whetherToShow: 1,
        // 类型 ===> 0：普通答案，1：真实姓名 2：所在项目 3：所在企业 4：电话号码
        answerType: 0,
        // 二级问题关联的id
        refQuestionId: '',
        // 选择题json
        answerJson: [
          {
            values: []
          }
        ],
        // 最多上传图片限制
        imgAmount: 1,
        // 名额限制单选题json
        userLimitJson: [],
        // 是否一级问题 => 默认为一级问题
        questionLevelFlag: 1,
        // 是否关联二级问题
        refQuestionFlag: 0

      },
      formItemLayout: {
        labelCol: {span: 10},
        wrapperCol: {span: 10},
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: {span: 3, offset: 10},
          sm: {span: 10, offset: 10},
        },
      },
      listActivityQuestionInfo: [],
      selectedCommonQuestions: [],
      // 常见问题
      commonQuestions: [
        {
          value: 1,
          label: '您的姓名'
        },
        {
          value: 2,
          label: '您所在园区'
        },
        {
          value: 3,
          label: '您的企业名称'
        },
        {
          value: 4,
          label: '您的电话'
        }
      ]
    }
  },
  props: ['questionInfo', 'isDisabled'],
  watch: {
    questionInfo: {
      handler(newVal, oldVal) {
        if(newVal && newVal.length) {
          this.echoData()
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * 问题名称change事件
     */
    questionContentChange(data) {
      const { refQuestionFontId, questionLevelFlag } = data
      // 如果是二级问题
      if (!questionLevelFlag) {
          const questionIndex = this.relevancyQuestions.findIndex(item => item.refQuestionFontId === refQuestionFontId)
          if (questionIndex >=0) {
              const newRelevancyQuestions = JSON.parse(JSON.stringify(this.relevancyQuestions));
              newRelevancyQuestions[questionIndex] = data
              this.relevancyQuestions = newRelevancyQuestions
          }
      }
    },

    // 回显数据
    echoData() {
      this.listActivityQuestionInfo = this.questionInfo && this.questionInfo.length && this.questionInfo.map(item => {
        return {
          ...item,
          // 常见问题不可编辑
          disabled: item.answerType > 0
        }
      })
      const commonQuestions = this.questionInfo && this.questionInfo.length && this.questionInfo.filter(item => item.answerType > 0)
      if(commonQuestions && commonQuestions.length) {
        let commonQuestionValues = []
        commonQuestions.forEach(item => {
          commonQuestionValues.push(item.answerType)
        })
        this.selectedCommonQuestions = commonQuestionValues
      }
      // 处理单选问题中如果有二级问题
      this.listActivityQuestionInfo.forEach(item => {
        if (!item.questionLevelFlag) {
          this.relevancyQuestions.push(item)
        }
      })
    },
    // 生成一个随机id，当前时间戳+ 0 - 1000的随机数
    createRandom() {
      const min = 1;
      const max = 1000;
      const randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
      return moment().valueOf() + randomInt.toString()
    },
    addQuestion(data) {
      let item = JSON.parse(JSON.stringify(this.basicTemplate))
      // 填空题
      if (data === 1) {
        item.questionType = 1;
      } else if (data === 2) {
        item.questionType = 2;
      } else if (data === 3) {
        item.questionType = 3
      } else if (data === 4) {
        item.questionType = 4
      } else if (data === 5) {
        item.questionType = 5
        item.mostSelect = 1
      }
      if (data === 3) {item.whetherToShow = 0}
      // 如果data ===> 常见问题，常见问题放到回答问题的前面
      if(data === 6) {
        item.questionType = 1
        // 根据选中的常见问题去添加问题
        this.addCommonQuestion(item)
      } else {
        // 添加一个随机id
        item.refQuestionFontId = this.createRandom()
        this.listActivityQuestionInfo.push(item)
      }
    },
    // 根据选中的常见问题去添加问题
    addCommonQuestion(data) {
      let commonQuestion = []
      if (this.selectedCommonQuestions && this.selectedCommonQuestions.length) {
        commonQuestion =  this.selectedCommonQuestions.map(item => {
          let questionItem = {};
          // 选中的常见问题对象
          const selectedQuestion = this.commonQuestions.find(arr => arr.value === item)
          // 判断是否已经在问题列表中存在
          const index = this.listActivityQuestionInfo.findIndex(arr => selectedQuestion.value === arr.answerType)
          // 如果已经添加到问题列表中
          if (index >= 0 ) {
            questionItem = this.listActivityQuestionInfo[index]
          } else {
            questionItem = data
          }
          return {
            ...questionItem,
            questionContent: selectedQuestion.label,
            disabled: true,
            answerType: selectedQuestion.value,
            // 添加一个随机id
            refQuestionFontId: index === -1 ? this.createRandom() : questionItem.refQuestionFontId
          }
        })
      } else {
        commonQuestion = []
      }
      if(this.listActivityQuestionInfo && this.listActivityQuestionInfo.length) {
        // 所有问题中第一个自定义问题的下标
        const customizeQuestionIndex = this.listActivityQuestionInfo.findIndex(arr => !arr.answerType)
        if(customizeQuestionIndex > -1) {
          // 这是用户的自定义问题
          const customizeQuestions =  this.listActivityQuestionInfo.slice(customizeQuestionIndex);
          this.listActivityQuestionInfo = [...commonQuestion, ...customizeQuestions]
        } else {
          this.listActivityQuestionInfo = commonQuestion
        }
      } else {
        this.listActivityQuestionInfo = commonQuestion
      }

    },
    handleSubmit(e) {
      if (this.checkData()) {
        if (this.checkDataRepeated()) {
          const newListActivityQuestionInfo = JSON.parse(JSON.stringify(this.listActivityQuestionInfo))
          this.$parent.finished(newListActivityQuestionInfo)
        }
      } else {
        this.$message.error('请完善问题信息！')
        return;
      }
    },
    // 检查数据是否填写完成
    checkData() {
      let flag = true;
      const newListActivityQuestionInfo = JSON.parse(JSON.stringify(this.listActivityQuestionInfo))
      newListActivityQuestionInfo.forEach(item => {
        // 如果不是必填则返回0
        if (!item.whetherRequired) {
          item.whetherRequired = 0
        }
        // 如果app详情页不需要展示则返回0
        if (!item.whetherToShow) {
          item.whetherToShow = 0
        }
        if (item.questionContent === '' || item.questionContent === null) {
          flag = false
        }
        if (item.questionType === 1) {
          if (item.mostWord === '' || item.mostWord === null  || item.leastWord === '' || item.leastWord === null) {
              flag = false
          }
        } else if (item.questionType === 2) {
          if (item.answerJson[0].values.length === 0) {
            flag = false
          } else {
            item.answerJson[0].values.forEach(arr => {
              if (arr.text === null || arr.text === '') {
                flag = false
              }
            })
          }
        } else if (item.questionType === 3) {
          if (item.imgAmount === '' || item.imgAmount === null) {
            flag = false
          }
        } else if (item.questionType === 4){
          if (item.userLimitJson.length === 0) {
            flag = false
          } else {
            item.userLimitJson.forEach(arr => {
              if (arr.text === '' || arr.text === null || arr.total === '' || arr.total === null) {
                flag = false
              }
            })
          }
        } else if (item.questionType === 5) {
          item.answerJson[0].values.forEach(arr => {
            if (arr.text === '' || arr.text === null) {
              flag = false
            }
          })
        }
      })
      return flag;
    },
    // 检查数据中选项是否重复了
    checkDataRepeated() {
      let flag = true;
      let questionContentSet = new Set();
      const newData = JSON.parse(JSON.stringify(this.listActivityQuestionInfo))
      newData.forEach(item => {
        questionContentSet.add(item.questionContent)
          if (item.questionType === 2) {
            let answerJsonSet = new Set()
            item.answerJson[0].values.forEach(arr => {
              answerJsonSet.add(arr.text)
            })
            if (answerJsonSet.size !== item.answerJson[0].values.length) {
              flag = false
              this.$message.error('单选题的选项重复了！')
            }
          }
          if (item.questionType === 4) {
            let userLimitJsonset = new Set();
            item.userLimitJson.forEach(arr => {
              userLimitJsonset.add(arr.text)
            })
            if (userLimitJsonset.size !== item.userLimitJson.length) {
              flag = false;
              this.$message.error('名额限制单选题的选项重复了！')
            }
          }
          if (item.questionType === 5) {
            const num = item.answerJson[0].values.length
            if (num < item.mostSelect) {
              flag = false;
              this.$message.error('多选题最多可选数量不能大于选项数量！')
            }
          }
      })
      if (questionContentSet.size !== this.listActivityQuestionInfo.length) {
        flag = false
        this.$message.error('问题题目重复了！')
      }
      return flag;
    },
    add(index) {
      let obj = {
        text: ''
      }
      this.listActivityQuestionInfo[index].answerJson[0].values.push(obj)
    },
    // 名额限制单选题新增
    addUserLimitOption(index) {
      let obj = {
        text: '',
        total: ''
      }
      this.listActivityQuestionInfo[index].userLimitJson.push(obj)
    },
    //
    removeUserLimitOption(optionIndex, index) {
      this.listActivityQuestionInfo[index].userLimitJson.splice(optionIndex, 1)
    },
    remove(optionIndex, index) {
      this.listActivityQuestionInfo[index].answerJson[0].values.splice(optionIndex, 1)
    },
    // 删除问题
    removeQuestion(index) {
      const currentDeleteQuestionType = this.listActivityQuestionInfo[index].answerType
      // 如果当前要删除的问题是二级问题
      if (!this.listActivityQuestionInfo[index].questionLevelFlag) {
          // 查询出当前二级问题的下标
          const i = this.relevancyQuestions.findIndex(item => item.refQuestionFontId === this.listActivityQuestionInfo[index].refQuestionFontId)
          if (i >= 0) {
              // 如果要删除的二级问题已经被关联，则要删除关联的问题
              this.removeAlreadyLinkedQuestion(this.listActivityQuestionInfo[index].refQuestionFontId)
              const newRelevancyQuestions = JSON.parse(JSON.stringify(this.relevancyQuestions));
              newRelevancyQuestions.splice(i, 1)
              this.relevancyQuestions = newRelevancyQuestions
          }
      }
      this.listActivityQuestionInfo.splice(index, 1)
      if(currentDeleteQuestionType) {
        // 当前要删除的常见问题的下标
        const currentSelectedCommonQuestionIndex = this.selectedCommonQuestions.findIndex(item => item === currentDeleteQuestionType)
        this.selectedCommonQuestions.splice(currentSelectedCommonQuestionIndex, 1)
      }
    },
    /**
     *
     * @param
     */
    removeAlreadyLinkedQuestion(index) {
        const questions = JSON.parse(JSON.stringify(this.listActivityQuestionInfo));
        questions.forEach(item => {
            // 如果类型是二级问题
            if (item.questionType === 2 && item.answerJson && item.answerJson[0].values && item.answerJson[0].values.length) {
              item.answerJson[0].values = item.answerJson[0].values.map(arr => {
                return {
                  text: arr.text,
                  refQuestionId: arr.refQuestionId === index ? '' : arr.refQuestionId
                }
              })
            }
        })
      this.listActivityQuestionInfo = questions
    },
    // 最多可选的change事件
    mostSelectHandleChange(data, index) {
      console.log(data)
      console.log(index)
    },
    // 选择常见问题
    handleSelectCommonQuestion(val) {
      this.addQuestion(6)
    },
    /**
     * 公共问题的取消后触发
     * @param val
     */
    handleDeselectCommonQuestion(val) {
      const questionItem = this.listActivityQuestionInfo.find(arr => arr.answerType === val)
      // 从关联题目中删除
      const newRelevancyQuestions = JSON.parse(JSON.stringify(this.relevancyQuestions))
      const removeIndex = newRelevancyQuestions.findIndex(item => item.refQuestionFontId === questionItem.refQuestionFontId)
      if (removeIndex >= 0) {
        newRelevancyQuestions.splice(removeIndex, 1)
        this.removeAlreadyLinkedQuestion(questionItem.refQuestionFontId)
      }
      this.relevancyQuestions = newRelevancyQuestions
    },

    /**
     * 点击一级问题处理
     * @params item => 当前问题
     */
    handleWhetherOne(event, data, currentIndex) {
      const flag = event.target.checked;
      // 如果不为一级问题，则就是二级问题 => 二级问题可以在关联题目中选择
      if (flag) {

        // 从关联题目中删除
        const newRelevancyQuestions = JSON.parse(JSON.stringify(this.relevancyQuestions))
        const removeIndex = newRelevancyQuestions.findIndex(item => item.refQuestionFontId === data.refQuestionFontId)
        newRelevancyQuestions.splice(removeIndex, 1)
        this.relevancyQuestions = newRelevancyQuestions
        // 如果问题从二级问题改为一级问题，删除已经关联的数据
        const newQuestionList = JSON.parse(JSON.stringify(this.listActivityQuestionInfo))
        const newQuestions = []
        newQuestionList.forEach(item => {
          if(item.questionType === 2) {
            item.answerJson && item.answerJson.length && item.answerJson.forEach(arr => {
              arr.values && arr.values.length && arr.values.forEach(q => {
                if (q.refQuestionId === data.refQuestionFontId) {
                  q.refQuestionId = ''
                }
              })
            })
            newQuestions.push(item)
          } else {
            newQuestions.push(item)
          }
        })
        this.listActivityQuestionInfo = newQuestions
      } else {
        // 如果改为二级问题，则取消选项关联二级问题的选择
        this.listActivityQuestionInfo[currentIndex].refQuestionFlag = 0
        this.handleWhetherTwo({ target: { checked: false }}, this.listActivityQuestionInfo[currentIndex])
        // 如果是二级问题，就添加到关联题目中
        this.relevancyQuestions.push(data)
      }
    },
    /**
     * 点击选项关联二级问题的问题
     */
    handleWhetherTwo(event, data) {
      const flag = event.target.checked;
      if(!flag) {
        const newQuestionList = JSON.parse(JSON.stringify(this.listActivityQuestionInfo))
        const dataIndex = newQuestionList.findIndex(item => item.refQuestionFontId === data.refQuestionFontId)
        const handleItem = newQuestionList[dataIndex];
        handleItem.answerJson && handleItem.answerJson.length && handleItem.answerJson.forEach(item => {
          item.values && item.values.length && item.values.forEach(arr => {
            arr.refQuestionId = ''
          })
        })
        this.listActivityQuestionInfo = newQuestionList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.question-type-class {
  display: flex;
  justify-content: center;
  .common-question {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  button {
    margin: 20px;
  }
}

.ant-form-item {
  margin-bottom: 0 !important;
}

#components-form-demo-validate-other .dropbox {
  height: 180px;
  line-height: 1.5;
}

</style>
