<template>
  <div>
    <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="是否需要报名" :required="true">
        <a-radio-group v-model="form.isApply" :disabled="isDisabled">
          <a-radio :value="1">
            是
          </a-radio>
          <a-radio :value="0">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="activityTitle" label="标题" prop="activityTitle">
        <a-input v-model.trim="form.activityTitle" :disabled="isDisabled"/>
      </a-form-model-item>
      <a-form-model-item label="展示图：" prop="coverImg">
        <a-upload
            :disabled="isDisabled"
            :action="IMG_API + '/oss/files'"
            list-type="picture-card"
            :file-list="imgFileList"
            :beforeUpload="beforeUploadImg"
            :showUploadList="{showPreviewIcon: false}"
            @change="handleChangeImg"
        >
          <div v-if="imgFileList.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              Upload
            </div>
          </div>
        </a-upload>
        <span style="color:red">注：图片大小不超过500KB</span>
      </a-form-model-item>
      <a-form-model-item label="分享图：" prop="shareImg">
        <a-upload
          :disabled="isDisabled"
          :action="IMG_API + '/oss/files'"
          list-type="picture-card"
          :file-list="shareImgFileList"
          :beforeUpload="beforeShareImgUploadImg"
          :showUploadList="{showPreviewIcon: false}"
          @change="handleChangeShareImg"
        >
          <div v-if="shareImgFileList.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              Upload
            </div>
          </div>
        </a-upload>
        <span style="color:red">注：图片大小不超过500KB</span>
      </a-form-model-item>

      <a-form-model-item ref="activityTitle" label="分享内容描述">
        <a-input v-model.trim="form.shareDescribe" :disabled="isDisabled"/>
      </a-form-model-item>
      <a-form-model-item ref="activityWay" label="活动方式" prop="activityWay">
        <a-radio-group v-model="form.activityWay" @change="activityWayChange" :disabled="isDisabled">
          <a-radio :value="1">
            线下活动
          </a-radio>
          <a-radio :value="2">
            商业活动
          </a-radio>
        </a-radio-group>
<!--        <a-select v-model="form.activityWay" placeholder="请选择活动方式" :disabled="isDisabled">-->
<!--          <a-select-option :value="1">-->
<!--            线下活动-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="1">-->
<!--            商业活动-->
<!--          </a-select-option>-->
<!--        </a-select>-->
      </a-form-model-item>
      <a-form-model-item ref="merchantIds" v-if="form.activityWay === 2" label="活动商户" prop="merchantIds">
        <div class="business-class">
          <span>
            <a-upload
              name="file"
              :multiple="true"
              action="#"
              accept=".xlsx"
              :showUploadList="false"
              @change="handleChangeFile"
            >
             <div v-if="!isDisabled">
                <a-button type="primary"> <a-icon type="upload"  /> 上传 </a-button>
                <a-button type="link" style="margin-left: 15px" @click="downloadTemplate" >下载模板</a-button>
             </div>
            </a-upload>
<!--            <p v-for="item in configForm.activityConfigMerchantList" :key="item.merchantId" style="line-height: 13px">-->
<!--              {{ item.merchantName }}-->
<!--            </p>-->
          </span>
          <div v-show="merchantData.length > 0">
            <a-select
              style="width: 200px"
              mode="multiple"
              placeholder="请选择"
              v-model="form.merchantIds"
              :disabled="isDisabled"
            >
              <a-select-option v-for="item in merchantData" :key="item.merchantId">
                {{ item.merchantName }}
              </a-select-option>
            </a-select>
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item ref="restrictAmount" v-if="form.activityWay === 2"  label="单笔实际付款金额大于等于" prop="restrictAmount">
        <a-input-number :disabled="isDisabled" v-model.trim="form.restrictAmount"  :min="0"/>元
      </a-form-model-item>
      <a-form-model-item ref="applyTime" v-if="form.isApply !== 0 && form.activityWay !== 2" label="报名时间" prop="applyTime">
        <a-range-picker
          :disabled="isDisabled"
          v-model="form.applyTime"
          show-time format="YYYY-MM-DD HH:mm:ss"
          :style="{ width: '100%' }"
        />
      </a-form-model-item>
      <a-form-model-item ref="activityTime" label="活动时间" prop="activityTime">
        <a-range-picker :style="{ width: '100%' }" :disabled="isDisabled" v-model="form.activityTime"
                        show-time format="YYYY-MM-DD HH:mm:ss"
        />
      </a-form-model-item>
      <a-form-model-item ref="activityAddress" label="活动地点" prop="activityAddress">
        <a-input
            v-model.trim="form.activityAddress"
            :disabled="isDisabled"
            @blur="
          () => {
            $refs.activityAddress.onFieldBlur();
          }
        "
        />
      </a-form-model-item>
      <a-form-model-item ref="numberPersonLimit" v-if="form.isApply !== 0" label="人数限制" prop="numberPersonLimit">
        <a-input-number :style="{ width: '100%' }" :disabled="isDisabled" id="inputNumber" v-model="form.numberPersonLimit" :min="0" :max="9999"/>
      </a-form-model-item>
<!--      活动详情-->
      <a-form-model-item ref="activityDetail" :wrapper-col="wrapperCol1" style="z-index: 0" label="活动详情" prop="activityDetail">
        <div id="editer"></div>
      </a-form-model-item>
      <a-form-model-item label="报名成功活动群二维码" v-if="form.isApply !== 0">
        <a-upload
            :action="IMG_API + '/oss/files'"
            list-type="picture-card"
            :file-list="qrcodeFileList"
            :beforeUpload="beforeUploadQrocdeImg"
            :showUploadList="{showPreviewIcon: false}"
            @change="handleChangeQrcode"
            :disabled="isDisabled"
        >
          <div v-if="qrcodeFileList.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              Upload
            </div>
          </div>
        </a-upload>
        <span style="color:red">注：建议上传比例为1：1，大小不超过500KB</span>
      </a-form-model-item>
<!--        <a-select-->
<!--            :disabled="isDisabled"-->
<!--            mode="multiple"-->
<!--            style="width: 100%"-->
<!--            placeholder="请选择项目"-->
<!--            v-model="form.isShowProjects"-->
<!--        >-->
<!--          <a-select-option v-for="(item, index) in projects" :key="item.id">-->
<!--            {{ item.project_name }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
      <a-form-model-item ref="displayPriority" label="展示优先级" prop="displayPriority">
        <a-input-number v-model="form.displayPriority" :disabled="isDisabled" :style="{ width: '100%' }" :precision="0" placeholder="请输入展示优先级" :min="1" :max="999" />
      </a-form-model-item>
      <a-form-model-item ref="isShowProjects" label="可见项目" prop="isShowProjects">
        <a-tree-select
          :disabled="isDisabled"
          v-model="form.isShowProjects"
          style="width: 300px"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="请选择所属项目"
          :style="{ width: '100%' }"
          allow-clear
          multiple
          tree-default-expand-all
          :filterTreeNode="filterTreeProject"
        >
          <a-tree-select-node :disabled="item.disabled" :value="item.id" :title="item.project_name" v-for="item in projectList" :key="item.id">
            <a-tree-select-node :key="arr.id" :value="arr.id" :title="arr.project_name" v-for="arr in item.child">
            </a-tree-select-node>
          </a-tree-select-node>
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item ref="userCrowds" label="用户人群" prop="userCrowds">
        <a-select
            :disabled="isDisabled"
            mode="multiple"
            style="width: 100%"
            placeholder="请选择用户人群"
            v-model="form.userCrowds"
        >
          <a-select-option v-for="item in allCrowd" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="通行记录">
        <a-radio-group :disabled="isDisabled" v-model="form.accessLogState" name="radioGroup">
          <a-radio :value="1">
            是
          </a-radio>
          <a-radio :value="2">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <div v-show="form.accessLogState === 1">
        <a-form-model-item label="通行记录时间段">
          <a-range-picker :style="{ width: '100%' }" :disabled="isDisabled" v-model="form.dateTimePeriod" />
          <br/>
          <a-time-picker :style="{ width: '100%' }" :disabled="isDisabled" placeholder="开始时间" v-model="form.passStartTime" :default-open-value="moment('00:00:00', 'HH:mm:ss')" /> -
          <a-time-picker :style="{ width: '100%' }" :disabled="isDisabled" placeholder="结束时间" v-model="form.passEndTime" :default-open-value="moment('00:00:00', 'HH:mm:ss')" />
        </a-form-model-item>
        <a-form-model-item label="弹窗条件">
          <a-checkbox :disabled="isDisabled" v-model="form.popupCondition">
            根据用户通行记录
          </a-checkbox>
        </a-form-model-item>
        <a-form-model-item label="弹窗ID">
          <a-input :disabled="isDisabled" placeholder="请输入弹窗ID" v-model="form.popupId"/>
        </a-form-model-item>
      </div>
      <a-form-model-item label="活动标签">
        <a-radio-group v-model="form.activityLabelType" :disabled="isDisabled">
          <a-radio :value="10">
            热门
          </a-radio>
          <a-radio :value="20">
            普通
          </a-radio>
          <a-radio :value="30">
            回顾
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {findAllCrowd, getProjects} from '../../../../api/activityRegistration'
import {IMG_API} from "@/config";
import wangEditor from 'wangeditor'
import moment from 'moment';
import XLSX from "xlsx";
import {mapState} from "vuex";
export default {
  name: "stepOne",
  computed: {
    ...mapState({
      projectList: (state) => {
        return state.common.projects.length && state.common.projects.map(item => {
          return {
            ...item,
            disabled: !!(item.child)
          }
        })
      }
    })
  },
  data() {
    //校验时间
    let checkTime = (rule, value, callback) => {
      if (this.form.activityWay === 2) {
        return callback();
      }
      if (this.form.applyTime && this.form.activityTime) {
        if (moment(this.form.activityTime[0],"YYYY-MM-DD HH:mm:ss") >= (moment(this.form.applyTime[1], "YYYY-MM-DD HH:mm:ss"))) {
          return callback();
        }
      } else {
        return callback();
      }
      // 不合法
      callback(new Error("活动时间需大于报名时间"));
    };
    return {
      info: {},
      // 上传的商户信息
      merchantData: [],
      imgFileList:[],
      shareImgFileList: [],
      qrcodeFileList:[],
      moment,
      editor: null,
      disabled: false,
      loading: false,
      imageUrl: '',
      labelCol: {span: 10},
      wrapperCol: {span: 5},
      wrapperCol1: {span: 10},
      // 所有人群
      allCrowd: [],
      IMG_API: IMG_API,
      form: {
        // 是否需要报名
        isApply: 1,
        // 活动title
        activityTitle: '',
        // 展示图
        coverImg: [],
        // 分享图
        shareImg: [],
        // 分享内容描述
        shareDescribe: '',
        // 活动方式
        activityWay: 1,
        // 报名时间
        applyTime: null,
        // 活动时间
        activityTime: null,
        // 活动地点
        activityAddress: '',
        // 人数限制
        numberPersonLimit: 0,
        // 活动详情
        activityDetail: '',
        // 活动报名成功活动群二维码
        applySuccessCrowdCode: '',
        // 展示优先级
        displayPriority: '',
        // 可见项目
        isShowProjects: [],
        // 用户人群
        userCrowds: [],
        // 单笔实际付款金额大于等于
        restrictAmount: 0,
        // 商户ids
        merchantIds: [],
        // 选中的商户信息
        activityBusinessRestrictList: [],
        // 通行记录
        accessLogState: 1,
        // 日期时间段
        dateTimePeriod: null,
        // 通行记录开始时间
        passStartTime: null,
        // 通行记录结束时间
        passEndTime: null,
        // 弹窗条件（1：根据用户通行记录）
        popupCondition: false,
        // 弹窗ID
        popupId	: '',
        // 活动标签
        activityLabelType: null
      },
      rules: {
        activityTitle: [
          {required: true, message: '请输入活动标题', trigger: 'blur'},
          {max: 20, message: "活动标题不能超过20字符", trigger: 'blur'}
        ],
        coverImg: [
          {required: true, message: '请上传展示图', trigger: 'change'},
        ],
        shareImg: [
          {required: true, message: '请上传分享图', trigger: 'change'},
        ],
        activityWay: [
          {required: true, message: '请选择活动方式', trigger: 'change'},
        ],
        applyTime: [
          {required: true, message: '请选择活动报名时间', trigger: 'change'},
        ],
        activityTime: [
          {required: true, message: '请选择活动时间', trigger: 'change'},
          // { validator: checkTime, trigger: "change" },
        ],
        activityAddress: [
          {required: true, message: '请输入活动地点', trigger: 'blur'},
          { max: 50, message: '活动地点不能超过50字符', trigger: 'blur'},
        ],
        numberPersonLimit: [
          {required: true, message: '请输入活动人数限制', trigger: 'blur'},
        ],
        activityDetail: [
          {required: true, message: '请输入活动详情', trigger: 'blur, change'},
        ],
        displayPriority: [
          { required: true, message: '请输入展示优先级', trigger: 'blur' }
        ],
        isShowProjects: [
          {required: true, message: '请选择可见项目', trigger: 'change'},
        ],
        merchantIds: [
          {required: true, message: '请上传活动商户', trigger: 'blur, change'},
        ],
        restrictAmount: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      }
    }
  },
  props: ['activityInfo','isDisabled'],
  watch: {
    activityInfo: function (nv, ov) {
      this.echoData();
    },
    // 如果不需要报名
    'form.isApply': {
      handler(val) {
        if (val === 0) {
          this.form.applyTime = null
          this.form.numberPersonLimit = ''
          this.form.applySuccessCrowdCode = ''
          // 将下一步直接改为提交
          this.$parent.isNotApply(true)
        } else {
          this.$parent.isNotApply(false)
        }
      },
      deep:true,
      immediate: true
    }
  },
  updated () {
    this.$nextTick(() => {
      this.createEditor()
    })
  },
  created() {
    // 获取所有人群
    this.getAllCrowds();
  },
  methods: {
    // 可见项目搜索
    filterTreeProject(inputValue, treeNode) {
      return (
        treeNode.componentOptions.propsData.title.indexOf(inputValue) >= 0
      );
    },

    // 下载模板
    downloadTemplate() {
      location.href = "https://oss-workplace-prod.innoecos.cn/picture_prod/0606cd6834810000_%E5%95%86%E6%88%B7%E4%BF%A1%E6%81%AF.xlsx";
    },

    // 活动方式chenge事件
    activityWayChange(e) {
      const val = e.target.value;
      if (val === 2) {
        this.form.applyTime = undefined
      }
    },
    // 上传excel并解析数据
    handleChangeFile({ file }) {
      const reader = new FileReader();
      reader.onload = () => {
        const txt = reader.result;
        const wb = XLSX.read(txt, {
          type: "binary"
        });
        const json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        // 到json就可以获取到解析后的结果了
        const newData = json.map(item => {
          return {
            merchantId: item.商户id,
            merchantName: item.商户id + '-' + item.商户名称
          };
        });
        try {
          if (newData && newData.length === 0) {
            this.$message.error("excel没有读到数据!")
            throw new Error()
          }
          const checkData = json.map(item => {
            return {
              merchantId: item.商户id,
              merchantName: item.商户名称
            };
          });
          checkData.forEach(item => {
            if (!item.merchantId || !item.merchantName) {
              this.$message.error("excel数据不正确！")
              throw new Error()
            }
          })
        } catch (err) {
          return false;
        }
        this.merchantData = newData;
        this.form.merchantIds = []
        this.merchantData.forEach(item => {
          this.form.merchantIds.push(item.merchantId)
        })
      };
      if (file.status === "error") {
        reader.readAsBinaryString(file.originFileObj);
      }
    },
    // 获取所有人群
    getAllCrowds() {
      findAllCrowd().then(res => {
        if (res.code === '200') {
          this.allCrowd = res.data
        }
      })
    },
    echoData() {
      if (this.activityInfo.title !== '' && this.activityInfo.title !== undefined) {
        this.info = this.activityInfo
        this.form.activityTitle = this.info.title
        this.form.isApply = this.info.whetherRegistration
        this.form.coverImg = this.info.coverMap
        this.form.shareImg = this.info.shareImg
        this.form.applySuccessCrowdCode = this.info.wechatGroupErcode
        this.imgFileList = [{uid: 1, url: this.info.coverMap}]
        this.shareImgFileList = [{uid: 1, url: this.info.shareImg}]
        if (this.info.wechatGroupErcode !== null && this.info.wechatGroupErcode !== '') {
          this.qrcodeFileList = [{uid: 1, url: this.info.wechatGroupErcode}]
        }
        this.form.shareDescribe = this.info.shareRemark
        this.form.displayPriority = this.info.displayPriority
        this.form.activityAddress = this.info.activityPlace
        this.form.numberPersonLimit = this.info.peopleNumber
        this.form.activityDetail = this.info.activityDetails
        this.form.activityTime = [this.info.startTime, this.info.endTime]
        if (this.info.registrationStartTime && this.info.registrationEndTime) {
          this.form.applyTime = [this.info.registrationStartTime, this.info.registrationEndTime]
        } else {
          this.form.applyTime = undefined
        }
        this.form.activityWay = this.info.activityMode
        if (this.info.activityUserLabelList !== null) {
          this.form.userCrowds = this.info.activityUserLabelList.map(item => {
            return item.labelId
          })
        }
        if (this.info.projectList !== null) {
          this.form.isShowProjects = this.info.projectList.map(item => {
            return item.id
          })
        }
        if (this.info.activityBusinessRestrictList && this.info.activityBusinessRestrictList.length > 0) {
          this.merchantData = this.info.activityBusinessRestrictList
          this.info.activityBusinessRestrictList.forEach(item => {
            this.form.merchantIds.push(item.merchantId)
          })
        }
        if (this.info.restrictAmount) {
          this.form.restrictAmount = this.info.restrictAmount / 100
        }
        if (this.info.accessLogState) {
          this.form.accessLogState = this.info.accessLogState;
        }
        if (this.info.passStartDate && this.info.passEndDate) {
          this.form.dateTimePeriod = [this.info.passStartDate, this.info.passEndDate];
        }
        if (this.info.passStartTime && this.info.passEndTime) {
          this.form.passStartTime  = moment(this.info.passStartTime, 'HH:mm:ss');
          this.form.passEndTime = moment(this.info.passEndTime, 'HH:mm:ss');
        }
        if (this.info.popupCondition) {
          this.form.popupCondition = this.info.popupCondition
        }
        if (this.info.popupId) {
          this.form.popupId = this.info.popupId
        }
        if (this.info.activityLabelType) {
          this.form.activityLabelType = this.info.activityLabelType;
        }
      }
      this.$nextTick(() => {
        this.editor.txt.html(this.info.activityDetails);
      })
    },
    onSubmit() {
      // this.$parent.finishedToNext(this.form, 1);
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.form.merchantIds.length > 0) {
            this.form.activityBusinessRestrictList = this.form.merchantIds.map(item => {
              return {
                merchantId: item,
                merchantName: this.merchantData.find(arr => arr.merchantId === item).merchantName
              }
            })
          }
          try {
            if (moment(this.form.applyTime[0]).format('YYYY-MM-DD HH:mm:ss') > moment(this.form.activityTime[0]).format('YYYY-MM-DD HH:mm:ss')
              || moment(this.form.applyTime[1]).format('YYYY-MM-DD HH:mm:ss') > moment(this.form.activityTime[1]).format('YYYY-MM-DD HH:mm:ss')){
              this.$message.warn('报名时间不能大于活动时间')
              return false;
              throw new Error()
            }
            if ((this.form.accessLogState === 1 && !this.form.dateTimePeriod) || (this.form.accessLogState === 1
              && this.form.dateTimePeriod && !this.form.dateTimePeriod.length)) {
              this.$message.warning('通行数据选择"是"，通行记录时间段不能为空！')
              return false;
              throw new Error()
            }
            if (this.form.dateTimePeriod.length || this.form.passStartTime || this.form.passEndTime) {
              if ((this.form.passStartTime || this.form.passEndTime) && !(this.form.dateTimePeriod && this.form.dateTimePeriod.length)) {
                this.$message.warning('请选择通行记录日期段')
                return false;
                throw new Error()
              }
              if ((!this.form.passStartTime || !this.form.passEndTime) && (this.form.dateTimePeriod && this.form.dateTimePeriod.length)) {
                this.$message.warning('请选择通行记录时间段')
                return false;
                throw new Error()
              }
              if (this.form.passStartTime && !this.form.passEndTime) {
                this.$message.warning('请选择通行记录时间段结束时间')
                return false;
                throw new Error()
              }
              if (!this.form.passStartTime && this.form.passEndTime) {
                this.$message.warning('请选择通行记录时间段开始时间')
                return false;
                throw new Error()
              }

              if (this.form.passStartTime && this.form.passEndTime) {
                if (moment(this.form.passStartTime).format('HH:mm:ss') > moment(this.form.passEndTime).format('HH:mm:ss')) {
                  this.$message.warning('通行记录时间段结束时间不能小于开始时间')
                  return false;
                  throw new Error()
                }
              }
            }
          } catch (e) {}
          this.$parent.finishedToNext(this.form, 1);
        } else {
          return false;
        }
      });
    },
    beforeShareImgUploadImg(file) {
      let that = this
      // 校验格式
      this.commonlyUsedType = false;
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJpgOrPng = type === "png" || type === "jpg";
      if (!isJpgOrPng) {
        this.$message.error('图片格式只支持jpg，png 格式!');
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 <= 500;
      // 校验比例
      // const isSize = new Promise(function (resolve, reject) {
      //   const URL = window.URL || window.webkitURL;
      //   const img = new Image();
      //   img.onload = function () {
      //     const valid = that.reductionTo(img.width,img.height);
      //     valid ==='5:4' ? resolve() : reject();
      //   };
      //   img.src = URL.createObjectURL(file);
      // }).then(
      //   () => {
      //     return file;
      //   },
      //   () => {
      //     this.$message.error("请上传5:4的图片！");
      //     return Promise.reject();
      //   }
      // );
      if (!isLt2M) {
        this.$message.error("图片大小不能超过500KB");
        return Promise.reject();
      }
      return isJpgOrPng && isLt2M;
    },
    beforeUploadQrocdeImg(file) {
      // 校验格式
      this.commonlyUsedType = false;
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJpgOrPng = type === "png" || type === "jpg";
      if (!isJpgOrPng) {
        this.$message.error('图片格式只支持jpg，png 格式!');
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 <= 500;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过500KB");
        return Promise.reject();
      }
      return isLt2M;
    },
    beforeUploadImg(file) {
      // 校验格式
      this.commonlyUsedType = false;
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJpgOrPng = type === "png" || type === "jpg";
      if (!isJpgOrPng) {
        this.$message.error('图片格式只支持jpg，png 格式!');
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 <= 500;
      // // 校验比例
      // const isSize = new Promise(function (resolve, reject) {
      //   const URL = window.URL || window.webkitURL;
      //   const img = new Image();
      //   img.onload = function () {
      //     const valid = (img.width === 690 &&  img.height === 260);
      //     valid ? resolve() : reject();
      //   };
      //   img.src = URL.createObjectURL(file);
      // }).then(
      //   () => {
      //     return file;
      //   },
      //   () => {
      //     this.$message.error("请上传690*260的图片！");
      //     return Promise.reject();
      //   }
      // );
      if (!isLt2M) {
        this.$message.error("图片大小不能超过500KB");
        return Promise.reject();
      }
      return isJpgOrPng && isLt2M;
    },
    // 上传分享图
    handleChangeShareImg({fileList}) {
      if (fileList.length === 0) {
        this.form.shareImg = ""
      }
      this.shareImgFileList = fileList;
      if (this.shareImgFileList[0].response) {
        this.form.shareImg = this.shareImgFileList[0].response.redirect_uri
      }
    },
    // 上传展示图
    handleChangeImg({fileList}) {
      if (fileList.length === 0) {
        this.form.coverImg = ""
      }
      this.imgFileList = fileList;
      if (this.imgFileList[0].response) {
        this.form.coverImg = this.imgFileList[0].response.redirect_uri
      }
    },
    handleChangeQrcode({fileList}) {
      if (fileList.length === 0) {
        this.form.applySuccessCrowdCode = ""
      }
      this.qrcodeFileList = fileList;
      if (this.qrcodeFileList[0].response) {
        this.form.applySuccessCrowdCode = this.qrcodeFileList[0].response.redirect_uri
      }
    },
    // 校验图片比例
    reductionTo(m, n) {
      let a = m;
      let b = n;
      (a >= b) ? (a = m, b = n) : (a = n, b = m);
      if (m != 1 && n != 1) {
        for (let i = b; i >= 2; i--) {
          if (m % i == 0 && n % i == 0) {
            m = m / i;
            n = n / i;
          }
        }
      }
      return m + ':' + n;
    },
    /**
     * 创建富文本
     */
    createEditor() {
      const _this = this
      const editor = new wangEditor(`#editer`)
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.form.activityDetail = newHtml
      }
      editor.config.menus = [
        'fontName',
        'fontSize',
        'foreColor',
        'backColor',
        'underline',
        'italic',
        'bold',
        'justify',
        'splitLine',
        'undo',
        'redo',
        'list',
        'table',
        'image',
        'video',
      ]
      editor.config.uploadVideoServer = this.IMG_API + '/oss/files'
      editor.config.uploadImgServer = this.IMG_API + '/oss/files'
      editor.config.uploadImgShowBase64 = false
      editor.config.showLinkImg = false
      editor.config.showLinkVideo = false
      editor.config.uploadVideoMaxSize = 5 * 1024 * 1024
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024
      editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif']
      editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图
      // editor.config.uploadVideoAccept = ['mp4']
      editor.config.uploadFileName = 'file'
      editor.config.uploadVideoName = 'file'
      editor.config.uploadVideoTimeout = 60000
      editor.config.uploadImgTimeout = 60000
      editor.config.uploadVideoHooks = {
        // 上传视频之前
        before: function(xhr, editor, files) {
          console.log(xhr)
          console.log(editor)
          console.log(files)
        },
        // 视频上传并返回了结果，视频插入已成功
        success: function(xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function(xhr, editor, resData) {
          _this.$message.error('上传请求失败')
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function(xhr, editor, resData) {
          _this.$message.error('上传请求失败')
        },
        // 上传视频超时
        timeout: function(xhr) {
          _this.$message.error('上传请求超时')
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function(insertVideoFn, result) {
          let videoHTML = '&nbsp;<video poster="' + result.redirect_uri + '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' + result.redirect_uri +'" controls style="max-width:100%"></video>&nbsp;';
          editor.cmd.do('insertHTML', videoHTML);
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          // insertVideoFn(result.redirect_uri)
        }
      }
      editor.config.uploadImgHooks = {
        // 上传视频之前
        before: function(xhr) {
        },
        // 视频上传并返回了结果，视频插入已成功
        success: function(xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function(xhr, editor, resData) {
          _this.$message.error('上传请求失败')
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function(xhr, editor, resData) {
          _this.$message.error('上传请求失败')
        },
        // 上传视频超时
        timeout: function(xhr) {
          _this.$message.error('上传请求超时')
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function(insertVideoFn, result) {
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          insertVideoFn(result.redirect_uri)
        }
      }
      editor.config.customAlert = function (s, t) {
        switch (t) {
          case 'success':
            _this.$message.success(s)
            break
          case 'info':
            _this.$message.info(s)
            break
          case 'warning':
            _this.$message.warning(s)
            break
          case 'error':
            _this.$message.error(s)
            break
          default:
            _this.$message.info(s)
            break
        }
      }
      editor.config.placeholder = '请输入活动详情'
      // 创建编辑器
      editor.create()
      if (_this.isDisabled) {
        editor.disable()
      }
      _this.editor = editor
      setTimeout(() => {
        _this.editor.txt.html(_this.form.activityDetail)
      },100);
    }
  }
}
</script>

<style scoped>
.business-class {
  border: 1px solid #bdbdbd;
  padding: 30px;
  display: flex;
}
</style>
